import styled from 'styled-components'

import Icon from '../../../components/atoms/Icon'

export const StickyContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 6rem;
  background-color: ${({ theme }) => theme.colors.arapawa};
  border-radius: 1rem;
  padding: 2rem 0;
`

export const StyledIcon = styled(Icon)`
  width: 2.4rem;
  height: 2.4rem;
  color: ${({ theme }) => theme.colors.pureWhite};
`

export const Text = styled.div`
  ${({ theme }) => theme.textStyles.titleH6};
  color: ${({ theme }) => theme.colors.pureWhite};
  text-transform: uppercase;
`

export const Count = styled.div`
  ${({ theme }) => theme.textStyles.titleH8};
  font-weight: 700;
  background-color: ${({ theme }) => theme.colors.red};
  color: ${({ theme }) => theme.colors.pureWhite};
  border-radius: 1.2rem;
  padding: 0.2rem 0.8rem;
`
