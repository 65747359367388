import { FC, useEffect, useState } from 'react'

import * as SC from './styled'

type StickyProps = {
  className?: string
  children: React.ReactNode
}

const Sticky: FC<StickyProps> = (props) => {
  const { className, children } = props
  const [isTop, setIsTop] = useState(true)

  const handleScroll = () => {
    if (window.scrollY === 0) {
      setIsTop(true)
    } else {
      setIsTop(false)
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      handleScroll()
      window.addEventListener('scroll', handleScroll)
    }
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <SC.Container className={className} $isTop={isTop}>
      {children}
    </SC.Container>
  )
}

export default Sticky
