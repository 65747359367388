import styled from 'styled-components'

import { mq, sizes } from '../../../theme'

export const Container = styled.div<{ $isTop: boolean }>`
  width: 100%;
  position: fixed;
  top: 6rem;
  left: 0;
  padding: 1rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  z-index: ${({ theme }) => theme.zIndexes.default};

  @media ${mq(sizes.tablet)} {
    display: none;
  }
`
